import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { DomElement } from 'domhandler';

import Slide from 'bloko/blocks/slide';
import Item from 'bloko/blocks/slide/Item';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import useServerEnv from 'bloko/common/hooks/useServerEnv';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

import ArticleAlbumControls from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleAlbum/ArticleAlbumControls';
import ArticleAlbumPhoto from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleAlbum/ArticleAlbumPhoto';
import { AlbumItem } from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleAlbum/types';

import styles from './style.less';

// Markup example
/*
<div class="cms-album HH-Pages-Article-AlbumSlider">
    <div class="cms-album__list HH-Pages-Article-AlbumSlider-List Bloko-Swipe" style="transform: translate3d(0%, 0px, 0px);">
        <div class="cms-album__item HH-Pages-Article-AlbumSlider-Item cms-album__item_active">
            <div class="cms-album__placeholder cms-album__frame">
                <div class="cms-album__screen">
                    <span class="bloko-loading HH-Pages-Article-AlbumSlider-Loader"><span class="bloko-icon bloko-icon_loading bloko-icon_initial-primary"></span></span>
                    <img
                        class="cms-album__image HH-Pages-Article-AlbumSlider-Image"
                        src="https://hhcdn.ru/icms/10107424.jpeg"
                        data-srcset="https://hhcdn.ru/icms/10107424.jpeg , https://hhcdn.ru/icms/10107425.jpeg 1340"
                        title="aaaaa"
                        alt="aaaaa"
                    />
                </div>
            </div>
            <div class="cms-album__info">Мы радуемся обновлениям наших продуктов (и красиво раскладываем пирожные)</div>
        </div>
    </div>
    <div class="cms-album__toolbar cms-album__frame">
        <div class="cms-album__control">
            <span class="cms-album__arrow cms-album__arrow_prev HH-Pages-Article-AlbumSlider-Prev"></span>
            <span class="cms-album__counter">
                <span class="cms-album__counter-current HH-Pages-Article-AlbumSlider-Current">1</span>
                /
                <span class="cms-album__counter-total HH-Pages-Article-AlbumSlider-Total">4</span>
            </span>
            <span class="cms-album__arrow cms-album__arrow_next HH-Pages-Article-AlbumSlider-Next"></span>
        </div>
    </div>
</div>
 */

const getInfoFromAlbumItemElement = (itemElement: DomElement): AlbumItem | null => {
    const [frameElement, infoElement] = itemElement.children || [];
    const imageElement = frameElement?.children?.[0].children?.[1];
    const imageAttribs = imageElement?.attribs;
    if (!imageAttribs || !infoElement) {
        return null;
    }
    const { title, alt, 'data-srcset': srcsetString } = imageAttribs;
    const srcOptions = { large: '', lessThanLarge: '' };
    srcsetString.split(', ').forEach((srcStrDirty) => {
        const [srcStrPure, resolution] = srcStrDirty.split(' ');
        if (resolution === '1340') {
            srcOptions.lessThanLarge = srcStrPure;
        } else {
            srcOptions.large = srcStrPure;
        }
    });
    return {
        title,
        alt,
        infoElement,
        srcOptions,
    };
};

const ArticleAlbum: TagMapperComponent<TagMapperProps> = ({ options, originalElement }) => {
    const { children } = originalElement;
    const [activeItem, setActiveItem] = useState(0);
    const [items, setItems] = useState<AlbumItem[]>([]);
    const isL = useBreakpoint(Breakpoint.L) === Breakpoint.L;
    const isServerEnv = useServerEnv();

    useEffect(() => {
        if (!isServerEnv && children) {
            const listElement = children[0];
            const itemElements = listElement.children!;
            setItems(itemElements.map(getInfoFromAlbumItemElement).filter((info) => !!info));
        }
    }, [children, isServerEnv]);

    const handleChangeSlide = (activeSlide: number) => {
        setActiveItem(activeSlide);
    };

    const handleClick = (activeSlide: number) => {
        let index = activeSlide;

        if (activeSlide < 0) {
            index = items.length - 1;
        }

        if (activeSlide >= items.length) {
            index = 0;
        }

        setActiveItem(index);
    };

    return (
        <div className={classnames('cms-album', styles.placeholderContainer)}>
            <div className="cms-album__list">
                {items.length === 0 ? (
                    <div className="cms-album__item">
                        <div className="cms-album__placeholder cms-album__frame"></div>
                    </div>
                ) : (
                    <Slide activeItem={activeItem} onChange={handleChangeSlide} transitionTiming={300}>
                        {items.map((item, index) => (
                            <Item key={index}>
                                <ArticleAlbumPhoto
                                    item={item}
                                    isL={isL}
                                    options={options}
                                    originalElement={originalElement}
                                />
                            </Item>
                        ))}
                    </Slide>
                )}
            </div>
            {items.length === 0 ? (
                <ArticleAlbumControls />
            ) : (
                <ArticleAlbumControls
                    handleClick={handleClick}
                    itemsCount={items.length}
                    activeItemIndex={activeItem}
                />
            )}
        </div>
    );
};

export default ArticleAlbum;
