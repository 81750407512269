import { domToReact } from 'html-react-parser';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

import { AlbumItem } from 'src/pages/Article/components/Content/BlogArticle/mappers/ArticleAlbum/types';

interface ArticleAlbumPhotoProps extends TagMapperProps {
    item: AlbumItem;
    isL: boolean;
}

const ArticleAlbumPhoto: TagMapperComponent<ArticleAlbumPhotoProps> = ({ options, item, isL }) => {
    return (
        <div className="cms-album__item cms-album__item_active">
            <div className="cms-album__placeholder cms-album__frame">
                <div className="cms-album__screen">
                    <img
                        className="cms-album__image"
                        src={isL ? item.srcOptions.large : item.srcOptions.lessThanLarge}
                        title={item.title}
                        alt={item.alt}
                    />
                </div>
            </div>
            <div className="cms-album__info">
                {item.infoElement.children && domToReact(item.infoElement.children, options)}
            </div>
        </div>
    );
};

export default ArticleAlbumPhoto;
