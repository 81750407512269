import { domToReact } from 'html-react-parser';

import Analytics, { VendorAnalytics } from '@hh.ru/analytics-js';
import { Link as SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoButton, { ButtonKind } from 'bloko/blocks/button';

import { TagMapperComponent, TagMapperProps } from 'src/hooks/useParseHtml';

interface ArticleButtonLinkProps extends TagMapperProps {
    kind?: ButtonKind;
}

interface AnalyticsData {
    toStorage: boolean;
    category: string;
    action: string;
    label: string;
}

const ArticleButtonLink: TagMapperComponent<ArticleButtonLinkProps> = ({
    options,
    originalElement: { attribs, children },
    kind,
}) => {
    if (!children || !attribs) {
        return null;
    }
    const [caption, analyticsScriptElement] = children;
    const sendAnalytics = () => {
        const dataset = JSON.parse(analyticsScriptElement?.attribs?.['data-params'] || '') as AnalyticsData;
        const { toStorage, category, action, label } = dataset;
        if (toStorage) {
            VendorAnalytics.eventToStorage(category, action, label);
        } else {
            Analytics.sendEvent(category, action, label);
        }
    };
    return (
        <BlokoButton
            Element={SPALink}
            to={attribs.href}
            target={attribs.target}
            kind={kind}
            onClick={sendAnalytics}
            nonMagritteExternalLink
        >
            {domToReact([caption], options)}
        </BlokoButton>
    );
};

export default ArticleButtonLink;
